import React from "react";

export default function How (){
    return(
        <section>
            <div className="question">
                <span>How?</span>
            </div>
            <div className="answer how">
                <span className="ans">We aim to provide you with...</span>
                <div className="details">
                    <div className="communities">
                        <div className="community"></div>
                        <span>Community</span>
                    </div>

                    <div className="competitions">
                        <div className="trophy"></div>
                        <span>Competitions</span>
                    </div>

                    <div className="jobs">
                        <div className="job"></div>
                        <span>Jobs</span>
                    </div>

                    <div className="resources">
                        <div className="resource"></div>
                        <span>Resources</span>
                    </div>

                    <div className="mentorship">
                        <div className="handshake"></div>
                        <span>Mentorship</span>
                    </div>

                </div>
            </div>
        </section>


    )
}