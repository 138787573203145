import React, { useContext } from "react";
import { members } from "../data/teamData";
import { UserContext } from "../context/userContext";

export default function OurTeam() {

    const {handleTouch} = useContext(UserContext);

    return (
        <section onClick={handleTouch}>
            <div className="teamContainer">
                {members.map((el, index) => (
                    <div className="members" key={index}>
                        <div className="icon"> </div>
                        <div className="name"> {el.name}</div>
                        <div className="role"> {el.role}</div>
                        <div className="socialMedia">
                            <a href={el.instagram} className="ig">
                                <span className="visually-hidden">Instagram</span>
                            </a>
                            <a href={el.linkedin} className="linked">
                                <span className="visually-hidden">LinkedIn</span>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}
