export const members = [
    {
        name: 'Aurelia Brzezowska',
        role: 'CEO',
        instagram: 'https://www.instagram.com/kasia.aur?igsh=MXZtMWpyenhzNnQ1YQ==',
        linkedin: 'https://www.linkedin.com/in/aurelia-brzezowska?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
    },
    {
        name: 'Ilenia Maietta',
        role: 'Content Manager & Mentor',
        instagram: 'https://www.instagram.com/ilenia.digital?igsh=MmdoMjM3d28yZmE0',
        linkedin: 'https://www.linkedin.com/in/ilenia-maietta?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
    },
    {
        name: 'Manpreet Kaur',
        role: 'Web Developer',
        instagram: 'https://www.instagram.com/randhawa._.manpreet?igsh=aDd2czR3OHE1bndn&utm_source=qr',
        linkedin: 'https://www.linkedin.com/in/manpreet-kaur-b9bb68280?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
        
    },
    {
        name: 'Nadira Alom',
        role: 'Social Media Manager',
        instagram: 'https://www.instagram.com/nadira_alom?igsh=OW5jM3Z5YWJsMGk0',
        linkedin:'https://www.linkedin.com/in/nadira-alom-841417246/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
    },
    {
        name: 'Saloni Desai',
        role: 'Social Media Manager',
        instagram: 'https://www.instagram.com/salonidreams?igsh=dHpwcXVjdzBvZTd1',
        linkedin:'https://www.linkedin.com/in/saloni-desai-5841061b7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'
    }
]
