import React, { useEffect, useState } from "react";
import { bits } from "../data/data";

export default function Bookmark(){

    const [counter, setCounter] = useState(0);

    useEffect(()=>{
        const interval = setInterval(()=>{
            setCounter((prev) => (prev+1)%bits.length)
        },3000);
        return ()=> clearInterval(interval);
    }, [])
    return(
        <>
        <div className="bookmark">
            <div className="bitContainer">
                <span className="bits">{bits[counter]}</span>
            </div>
        </div>
        </>
    )
}