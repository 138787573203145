import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from 'react-router-dom';
import Bookmark from "./bookmark";
import { greeting } from "../data/data";
import ApplyButton from "./applyButton";
import { UserContext } from "../context/userContext";


export default function Welcome(){

    const {handleTouch} = useContext(UserContext);

    const [counter, setCounter] = useState(0);
    
    const navigate =  useNavigate();

    const handleClick = ()=>{
        navigate('/applyNow')

    }

    

    useEffect(()=>{
        const interval = setInterval(()=>{
            setCounter((prevCounter)=>(prevCounter+1)%greeting.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [])

    return(
        <main onClick={handleTouch}>
            <Bookmark />
            <div className="welcome">
                <span> {greeting[counter]}</span>
            </div>
            <div className="byteQueens">
                <span>BYTE QUEENS</span>
            </div>
            <ApplyButton  onClick={handleClick}/>
        </main>
    )
}