export const regEx={
        reg: /^[^\s@]+@[^\s@]+\.[^\s@]+$/

}

export const emailValidation = (email)=> {
    const isEmailValid =  regEx.reg.test(email);
    return isEmailValid;
    

}

export const messageValidation = (message) =>{
    const isMessageValid = message.trim().split(/\s+/).length >= 5;
    return isMessageValid;
}

export const nameValitaion = (name) =>{
    const isNameValid = name.length>=2;
    return isNameValid;
}