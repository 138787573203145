import React from "react";
import Header from "../navigation/header";
import Apply from "./apply";
import Footer from "../navigation/footer";

export default function ApplyPage(){
    return(
        <>
            <Header />
            <Apply />
            <Footer />
        </>
    )
}