import React, { useContext, useState } from "react";
import { jokes } from "../data/data";
import Computer from "./computer";
import { UserContext } from "../context/userContext";

export default function ContactUs() {
    const [joke] = useState(jokes[Math.floor(Math.random() * jokes.length)]);

    const {handleTouch} = useContext(UserContext);
    
    return (
        <main onClick={handleTouch}>
            <div className="title">Contact Us</div>
            <Computer />
            
            <div className="contactContainer">
                <span>
                    Have any burning questions? Feel free to reach out to us on our socials or on our email <strong>bytequeens@gmail.com</strong>. We aim to get back to you faster than {joke}.
                </span>
            </div>
        </main>
    );
}
