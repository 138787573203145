import React, { useContext } from "react";
import Who from "./who";
import How from "./how";
import Why from "./why";
import Lines from "./lines";
import { UserContext } from "../context/userContext";

export default function AboutUs(){
    
    const {handleTouch} = useContext(UserContext);
    
    return(
        <main className="aboutus" onClick={handleTouch}>
            <div className="aboutContainer">
            <Who />
            <Lines />

            <How />
            <Lines />

            <Why />
            
            </div>
        </main>
    )
}