import React, {useEffect, useState} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navigation from './mobileNavigation';

export default function Header (){

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const navigate =  useNavigate();

    const handleClick = ()=>{
        navigate('/');
    }
    return(
        <div className='header'>
            <div className='companyLogo'>
                <div className='logo' onClick={handleClick}></div>
            </div>
           
            <div className='navigation'>
                
                {isMobile ? 
                    <Navigation />
                : (
                    <ul>
                        <Link to={'/aboutUs'} className='link'>                    
                            <li>About</li>
                        </Link>

                        <Link to={'/contactUs'}  className='link'>                    
                            <li>Contact</li>
                        </Link>

                        <Link to={'/applyNow'} className='link'>
                            <li>Apply</li>
                        </Link>
                        
                        <Link to={'/ourTeam'} className='link'>
                            <li>Our Team</li>
                        </Link>

                        <Link to={'/suggestions'} className='link'>
                            <li>Suggestion</li>
                        </Link>
                    </ul>
                )}
            </div>
            
            
        </div>
    )
}