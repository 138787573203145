import React from "react";
import Header from "../navigation/header";
import Welcome from "./welcome";
import Footer from "../navigation/footer";

export default function HomePage(){
    return(
        <>
            <Header />
            <Welcome />
            <Footer />
        </>
    )
}