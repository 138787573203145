import React from "react";
import Header from "../navigation/header";
import ContactUs from "./contact";
import Footer from "../navigation/footer";


export default function ContactUsPage(){
    return(
        <>
            <Header />
            <ContactUs />
            <Footer />
        </>
    )
}