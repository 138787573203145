import React from "react";

export default function ApplyButton(props ){
    const {onClick} = props;
    
    return(
        <>
            <div className="apply" onClick={onClick}>
                <button type="button" className="applyButton">
                    Apply today!
                </button>
            </div>
        </>
    )
}