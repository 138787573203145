import React, {createContext, useState} from "react";

export const UserContext = createContext();

export default function UserProvider(props){

    const[isTouched, setIsTouched] = useState(false);

    const handleTouch = () => {
        setIsTouched(prev => !prev);
    };


    return (
        <UserContext.Provider value={{isTouched, setIsTouched, handleTouch}}>
            {props.children}
        </UserContext.Provider>
    );
    
}