import React from "react";
import Header from "../navigation/header";
import Footer from "../navigation/footer";
import SuggestionForm from "./suggestionForm";

export default function SuggestionPage(){
    return(
       <>
        <Header />
        <SuggestionForm />
        <Footer />
       </>
    )
}