import React from "react";
import Header from "../navigation/header";
import Footer from "../navigation/footer";
import OurTeam from "./ourTeam";

export default function OurTeamPage(){
    return(
        <>
            <Header />
            <OurTeam />
            <Footer />
        </>
    )
}