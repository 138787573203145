import React from "react";
import AboutUs from "./aboutUs";
import Header from "../navigation/header";
import Footer from "../navigation/footer";

export default function AboutUsPage(){
    return(
        <>
            <Header />
            <AboutUs />
            <Footer />
        </>
    )
}