import React from "react";

export default function Footer(){
    return(
        <footer>
            <div className="logos">
                <div className="circle">
                    <a href = 'https://www.instagram.com/bytequeens?igsh=MXFkYnc5ZGJhYjU5eA==' className="instagram" >
                        <span className="visually-hidden">Instagram</span>
                    </a>
                </div>
                <div className="circle circleYoutube">
                    <a href = 'https://youtube.com/@bytequeens?feature=shared' className="youtube" >
                        <span className="visually-hidden">Youtube</span>
                    </a>
                </div>
                <div className="circle">
                    <a href = 'https://www.linkedin.com/company/byte-queens/' className="linkedIn" >
                        <span className="visually-hidden">LinkedIn</span>
                    </a>
                </div>
                <div className="circle">
                    <a href="mailto:bytequeens@gmail.com?subject=Application help" className="mail">
                        <span className="visually-hidden">Gmail</span>
                    </a>
                </div>
            </div>
            <div className="copyright">&copy; 2024 Byte Queens</div>

        </footer>
    )
}