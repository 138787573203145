import React from "react";

export default function Computer (){
    return (
        <>
        <div className="computer">
            <div className="screen"> Whats Up</div>
            <div className="base"></div>
            <div className="keyboard"></div>
        </div>
        </>
    )
}