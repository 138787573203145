import React from "react";


export default function Lines(){
    return(
            <div className="lines">
                <div className="tab1"></div>
                <div className="tab2"></div>
                <div className="tab3"></div>
            </div>
    
    )
}