import React, { useContext } from "react";
import ApplyButton from "../home/applyButton";
import { UserContext } from "../context/userContext";


export default function Apply (){

    const {handleTouch} = useContext(UserContext);

    const handleClick = ()=>{
        window.location.href ='https://docs.google.com/forms/d/e/1FAIpQLSejuHRNckixvF5sxuVWTqyc1qye5tp_Cd3ZTnQjj29JTVpD2w/viewform';
    }
    return(
        <main className="applyMain" onClick={handleTouch}>
            <div className="applyContainer">
                <span className="title">Apply</span>
                <p className="paragraph">In order to build an effective network for you we need numbers. We are always looking for more mentors, mentees and program participants. Join us!</p>
            </div>
            <ApplyButton onClick={handleClick}/>            
        </main>
    )
}